import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import {
  UserDetails,
  Avatar,
  Name,
  Handle,
  TweetText,
  TweetDetails,
} from "../TweetCard/TweetCardStyles";
import {
  CharacterContainer,
  FixedTweetCardDiv,
  CharacterDiv,
  CharacterImg,
  Domain,
} from "./CharacterCardStyles";
import Loading from "../Loading/Loading";

export const sentimentParser = (maxSentiment) => {
  if (maxSentiment == "sad") {
    return "down bad. Touch grass 👺";
  }
  if (maxSentiment == "cringe") {
    return "fake and cringe 😬";
  }
  if (maxSentiment == "based") {
    return "based as hell 🫡";
  }
  if (maxSentiment == "alpha") {
    return "an alpha chad 💪";
  }
};

export const CharacterCard = (props) => {
  const [timeCreated, setTimeCreated] = useState();
  const [card, setCard] = useState(null);
  const [cardRendered, SetCardRendered] = useState(false);
  const [fontSize, setFontSize] = useState("1em");
  const cardRef = useRef();

  const imagePaths = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
    "/7.jpeg",
    "/8.jpeg",
    "/9.jpeg",
    "/10.jpeg",
    "/1.jpeg",
    "/11.jpeg",
    "/12.jpeg",
  ];
  const imageGenerator = () => {
    const randomIndex = Math.floor(Math.random() * imagePaths.length);
    const randomPath = imagePaths[randomIndex];
    if (!card) {
      if (props.sentiment == "sad") {
        setCard(`./assets/down_bad${randomPath}`);
      }
      if (props.sentiment == "cringe") {
        setCard(`./assets/cringe${randomPath}`);
      }
      if (props.sentiment == "based") {
        setCard(`./assets/based${randomPath}`);
      }
      if (props.sentiment == "alpha") {
        setCard(`./assets/chad${randomPath}`);
      }
    }
  };

  const adjustFontSize = (text) => {
    const textLength = text.length;
    if (textLength > 15) {
      setFontSize("0.8em");
    }
  };

  const exportAsImage = async () => {
    if (!cardRendered) return;
    const element = cardRef.current,
      canvas = await html2canvas(element, { useCORS: true }),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    props.setCharacterClip(canvas);
    props.setCharacter(link);
  };

  useEffect(() => {
    const formatTime = () => {
      const date = new Date();
      const timeFormatted = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const dateFormatted = date.toLocaleString("en-US", {
        month: "short",
        year: "numeric",
        day: "numeric",
      });
      return `${timeFormatted} • ${dateFormatted}`;
    };
    if (!props.loading) {
      setTimeCreated(formatTime());
      imageGenerator();
      if (!props.character) {
        SetCardRendered(true);
        exportAsImage();
      }
    }
    adjustFontSize(props.twitterUser.name);
  });

  return (
    <CharacterContainer>
      <FixedTweetCardDiv ref={cardRef}>
        <div style={{ display: "grid" }}>
          <UserDetails>
            <Avatar src={props.twitterUser.profile_image_url} alt="Avatar" />
            <Name style={{ fontSize: fontSize }}>{props.twitterUser.name}</Name>
            <Handle>@{props.twitterUser.handle}</Handle>
          </UserDetails>
          <Domain>alphatweet.com</Domain>
        </div>
        {props.loading === false ? (
          <>
            <TweetText>
              <span style={{ color: "#1DA1F2" }}>
                @{props.twitterUser.handle}
              </span>
              <span>&nbsp;you are</span> {sentimentParser(props.sentiment)}
            </TweetText>
            <CharacterDiv>
              <CharacterImg src={card} />
            </CharacterDiv>
            <TweetDetails>{timeCreated}</TweetDetails>
          </>
        ) : (
          <Loading />
        )}
      </FixedTweetCardDiv>
    </CharacterContainer>
  );
};

export default CharacterCard;
