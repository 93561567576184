import styled, { keyframes } from "styled-components";

const Fly = keyframes`
0%  { background-position: 0 0;}
33% { background-position: -250 0;}
66% { background-position: -450px 0;}`;

const LoadingBlob = keyframes`
0% { height: 3px; width: 3px;}
	25% { height: 7px; width: 7px;}
	50% { height: 5px; width: 5px; }
	100% { height: 3px; width: 3px; }`;

export const Animation = styled.div`
  display: block;
  position: relative;
  animation: 0.3s ${Fly} step-end infinite;
`;

export const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  height: 17px;
  @media screen and (max-width: 960px) {
    justify-content: start;
    margin-left: 0.75em;
  }
`;

export const LoadingText = styled.span`
  color: #404040;
  font-weight: bold;
  font-size: 14px;
`;

export const Blob = styled.span`
  border-radius: 50%;
  background-color: #404040;
  display: block;
  float: left;
  margin: 5px 2px;
  position: relative;
  top: 6px;
  height: 3px;
  width: 3px;
  animation: ${LoadingBlob} 1.2s infinite;
  animation-timing-function: ease-in-out;
  transform: translate3d(0, 0, 0);
`;

export const Blob2 = styled(Blob)`
  animation-delay: 150ms;
`;
export const Blob3 = styled(Blob)`
  animation-delay: 300ms;
`;
