import styled from "styled-components";
import Slider from "react-slick";

export const ReviewSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    padding: 30px;
    gap: 3rem;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 1;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;
