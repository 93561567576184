import React, { useState, useEffect } from "react";
import {
  TweetContainer,
  TweetCardDiv,
  Avatar,
  Name,
  UserDetails,
  Handle,
  TweetText,
  TweetDetails,
  Engagement,
  Icon,
  Archetype,
} from "./TweetCardStyles";

const TweetCard = (props) => {
  const [fontSize, setFontSize] = useState("1em");

  const adjustFontSize = (text) => {
    const textLength = text.length;
    if (textLength > 15) {
      setFontSize("0.85em");
    }
  };

  const formatTweetTime = (dateString) => {
    const date = new Date(dateString);

    const timeFormatted = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const dateFormatted = date.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });
    return `${timeFormatted} • ${dateFormatted}`;
  };

  const date_created = props.tweet.created_at;
  const formattedTime = formatTweetTime(date_created);

  const handleClick = (value) => () => {
    window.open(value, "_blank", "noopener,noreferrer");
  };

  const regex = /(https?:\/\/\S+|#\S+|@\S+)\b/g;

  const textArray = props.tweet.text.split(regex);

  const textElements = textArray.map((text, index) => {
    if (regex.test(text)) {
      return (
        <span key={index} style={{ color: "#1DA1F2" }}>
          {text}
        </span>
      );
    } else {
      return text;
    }
  });

  const archetype = (sentiment) => {
    if (sentiment == "sad") {
      return "Down Bad 👺";
    }
    if (sentiment == "cringe") {
      return "Cringe 😬";
    }
    if (sentiment == "based") {
      return "Based 🫡";
    }
    if (sentiment == "alpha") {
      return "Chad 💪";
    }
  };

  useEffect(() => {
    adjustFontSize(props.twitterUser.name);
  }, []);

  return (
    <TweetContainer>
      <TweetCardDiv
        style={props.homestyle}
        onClick={
          props.disabled
            ? undefined
            : handleClick(
                `https://twitter.com/${props.twitterUser.handle}/status/${props.tweet.id}`
              )
        }
      >
        <UserDetails>
          <Avatar src={props.twitterUser.profile_image_url} alt="Avatar" />
          <Name style={{ fontSize: fontSize }}>{props.twitterUser.name}</Name>
          <Handle>@{props.twitterUser.handle}</Handle>
        </UserDetails>
        <Archetype>{archetype(props.tweet.sentiment)}</Archetype>
        <TweetText>{textElements}</TweetText>
        <TweetDetails>{formattedTime}</TweetDetails>
        <Engagement>
          <Icon>🔁&nbsp;{props.tweet.retweets}</Icon>
          <Icon>❤️&nbsp;{props.tweet.likes}</Icon>
        </Engagement>
      </TweetCardDiv>
    </TweetContainer>
  );
};

export default TweetCard;
