import React, { useState, useEffect } from "react";
import {
  Button,
  ShareButton,
  CopyButton,
  AppHeading,
  AppBackground,
  AppContainer,
} from "../globalStyles";
import { ButtonWrapper } from "../components/Hero/HeroStyles";
import TweetCard from "../components/TweetCard/TweetCard";
import UserDetails from "../components/UserDetails/UserDetails";
import FriendGraph from "../components/FriendGraph/FriendGraph";
import CharacterCard, {
  sentimentParser,
} from "../components/CharacterCard/CharacterCard";
import Loading from "../components/Loading/Loading";

const Mainapp = (session) => {
  const [loading, setLoading] = useState(true);
  const [top_tweets, setTop_tweets] = useState(null);
  const [maxSentiment, setMaxSentiment] = useState(null);
  const [hasRun, setHasRun] = useState(false);
  const [character, setCharacter] = useState(false);
  const [graph, setGraph] = useState(null);
  const [characterClip, setCharacterClip] = useState(null);
  const [graphClip, setGraphClip] = useState(null);
  const [characterSrc, setCharacterSrc] = useState("./assets/clipboard.svg");
  const [graphSrc, setGraphSrc] = useState("./assets/clipboard.svg");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch("api/me/top_tweets");
        if (!response.ok) {
          throw new Error(
            JSON`This is an HTTP error: The status is ${response.status}`
          );
        }
        let tweets = await response.json();
        setTop_tweets(tweets);
      } catch (err) {
        setTop_tweets(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const countSentiment = (tweets) => {
    const counts = {};
    for (const key in tweets) {
      if (tweets.hasOwnProperty(key)) {
        if (tweets[key].hasOwnProperty("sentiment")) {
          const value = tweets[key]["sentiment"];
          if (!counts[value]) {
            counts[value] = 1;
          } else {
            counts[value] += 1;
          }
        }
      }
    }
    let count = 0;
    let maximum = null;
    for (const value in counts) {
      if (counts[value] > count) {
        count = counts[value];
        maximum = value;
      }
    }
    setMaxSentiment(maximum);
  };

  if (!hasRun && top_tweets != null) {
    countSentiment(top_tweets);
    setHasRun(true);
  }

  const handleClick = (imageFileName, link) => () => {
    link.download = imageFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClipboard = (canvas, setState) => () => {
    canvas.toBlob(function (blob) {
      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard.write([item]);
    });
    setState("./assets/tick.svg");
  };

  const LogOut = () => () => {
    document.cookie =
      "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    session.setSession(null);
  };

  return (
    <AppBackground>
      <UserDetails {...session} />
      <AppContainer>
        <div>
          <AppHeading>Your Archetype</AppHeading>
          <CharacterCard
            {...session}
            sentiment={maxSentiment}
            loading={loading}
            character={character}
            setCharacter={setCharacter}
            setCharacterClip={setCharacterClip}
          />
          {loading ? null : (
            <ButtonWrapper>
              <a
                href={`https://twitter.com/intent/tweet?text=I%20am%20${sentimentParser(
                  maxSentiment
                )}%20at%20http%3A%2F%2Falphatweet.com`}
                className="twitter-share-button"
              >
                <ShareButton src="./assets/twitter.svg" />
              </a>
              <CopyButton
                src="./assets/download.svg"
                onClick={handleClick(
                  `${session.twitterUser.handle}'s_character.png`,
                  character
                )}
              />
              <CopyButton
                src={characterSrc}
                onClick={handleClipboard(characterClip, setCharacterSrc)}
              />
            </ButtonWrapper>
          )}
        </div>
        <div>
          <AppHeading>Your Sentiment Graph</AppHeading>

          <FriendGraph
            profilePic={session.twitterUser.profile_image_url}
            sentiment={maxSentiment}
            loading={loading}
            graph={graph}
            setGraph={setGraph}
            setGraphClip={setGraphClip}
          />
          {loading ? null : (
            <ButtonWrapper>
              <a
                href={`https://twitter.com/intent/tweet?text=I%20am%20${sentimentParser(
                  maxSentiment
                )}%20at%20http%3A%2F%2Falphatweet.com`}
                className="twitter-share-button"
              >
                <ShareButton src="./assets/twitter.svg" />
              </a>
              <CopyButton
                src="./assets/download.svg"
                onClick={handleClick(
                  `${session.twitterUser.handle}'s_graph.png`,
                  graph
                )}
              />
              <CopyButton
                src={graphSrc}
                onClick={handleClipboard(graphClip, setGraphSrc)}
              />
            </ButtonWrapper>
          )}
        </div>
      </AppContainer>

      <AppHeading>Top Tweets</AppHeading>
      {loading === false ? (
        top_tweets.map((tweet, index) => {
          return <TweetCard key={index} tweet={tweet} {...session} />;
        })
      ) : (
        <Loading />
      )}
      <ButtonWrapper>
        <Button onClick={LogOut()} style={{ marginBottom: "10px" }}>
          Logout 👊
        </Button>
      </ButtonWrapper>
    </AppBackground>
  );
};

export default Mainapp;
