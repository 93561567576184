export const data = [
  {
    profile_image_url: "./assets/roon.jpeg",
    name: "roon",
    handle: "tszzl",
    id: 1490463530167848961n,
    text: "went to a party last night and everybody knew wordcel. it’s over",
    likes: 1451,
    retweets: 23,
    created_at: "2022-02-06T23:12:00.000Z",
    sentiment: "sad",
  },
  {
    profile_image_url: "./assets/daniel.jpeg",
    name: "Daniel",
    handle: "growing_daniel",
    id: 1601286175645319168n,
    text: "My husband and wife just left me 😩😩😩",
    likes: 1206,
    retweets: 36,
    created_at: "2022-12-09T18:42:00.000Z",
    sentiment: "sad",
  },
  {
    profile_image_url: "./assets/atlas.png",
    name: "atlas",
    handle: "creatine_cycle",
    id: 1561137138548486144n,
    text: "Girls GC names: giiiirrllss 🤪 Boys GC names: Indigenous land fracking acknowledgment national champions 2006-2009 against the UN Peacekeepers",

    likes: 37792,
    retweets: 3043,
    created_at: "2022-08-11T22:44:00.000Z",
    sentiment: "based",
  },
  {
    profile_image_url: "./assets/gabriel.jpeg",
    name: "Gabriel",
    handle: "gbrl_dick",
    id: 1552873146713182208n,
    text: "you’re at the jason calacanis vs founders fund cage match on a barge in international waters. a guy in the stands is selling raw beef liver hotdogs and modafinil. darkweb prediction markets keep getting shut down for running mortality odds. trae appears to be wearing a jet pack.",
    likes: 1627,
    retweets: 131,
    created_at: "2022-07-29T04:26:00.000Z",
    sentiment: "alpha",
  },
];

export const sliderSettings = {
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 8000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
