import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { Doughnut } from "react-chartjs-2";
import { Chart, Title, ArcElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  FriendGraphContainer,
  FriendGraphDiv,
  FriendLoadingDiv,
  ProfilePic1,
  ProfilePic2,
  ProfilePic3,
  ProfilePic4,
  data,
} from "./FriendGraphStyles";
import Loading from "../Loading/Loading";

Chart.register(ArcElement);
Chart.register(Title);
Chart.register(ChartDataLabels);

const FriendGraph = (props) => {
  const [chartRendered, SetChartRendered] = useState(false);
  const graphRef = useRef();

  const angles = [-60, -25, 20, 60];

  const PicPosition = (maxSentiment) => {
    if (maxSentiment == "sad") {
      return <ProfilePic1 src={props.profilePic} />;
    }
    if (maxSentiment == "cringe") {
      return <ProfilePic2 src={props.profilePic} />;
    }
    if (maxSentiment == "based") {
      return <ProfilePic3 src={props.profilePic} />;
    }
    if (maxSentiment == "alpha") {
      return <ProfilePic4 src={props.profilePic} />;
    }
  };

  const exportAsImage = async () => {
    if (!chartRendered) return;
    const element = graphRef.current,
      canvas = await html2canvas(element, { useCORS: true }),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    props.setGraphClip(canvas);
    props.setGraph(link);
  };

  useEffect(() => {
    if (!props.graph) {
      exportAsImage();
    }
  });

  return (
    <FriendGraphContainer>
      {props.loading === false ? (
        <>
          <FriendGraphDiv ref={graphRef}>
            {PicPosition(props.sentiment)}
            <Doughnut
              data={data}
              options={{
                animation: {
                  onComplete: function () {
                    SetChartRendered(true);
                  },
                },
                layout: {
                  padding: {
                    left: 15,
                    right: 15,
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: "alphatweet.com",
                    align: "center",
                  },
                  datalabels: {
                    color: "#404040",
                    display: true,
                    anchor: "end",
                    align: "top",
                    formatter: function (val, ctx) {
                      return ctx.chart.data.labels[ctx.dataIndex];
                    },
                    font: {
                      size: 14,
                      weight: 500,
                    },
                    rotation: function (ctx) {
                      return angles;
                    },
                  },
                },
                rotation: -90,
                circumference: 180,
                cutout: 0,
                maintainAspectRatio: false,
                responsive: true,
              }}
            />
          </FriendGraphDiv>
        </>
      ) : (
        <FriendLoadingDiv>
          <Loading />
        </FriendLoadingDiv>
      )}
    </FriendGraphContainer>
  );
};

export default FriendGraph;
