import styled from "styled-components";

export const TweetContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 960px) {
    justify-content: start;
  }
`;

export const TweetCardDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background: #fff;
  overflow: hidden;
  padding: 0.75em;
  margin: 0 15px 10px 15px;
  border: solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  row-gap: 1em;
  width: 650px;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  @media screen and (max-width: 960px) {
    width: fit-content;
  }
`;

export const UserDetails = styled.div`
  display: grid;
  grid-template-columns: 3;
  grid-template-rows: 1;
  width: fit-content;
  place-items: center;
  color: inherit;
  text-decoration: none;
  column-gap: 0.5em;
`;

export const Avatar = styled.img`
  grid-column: 1/2;
  width: 3.55em;
  height: 3.55em;
  border-radius: 50%;
  object-fit: cover;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const Name = styled.p`
  font-size: 1em;
  font-weight: 400;
  line-height: 13px;
  grid-column: 2/2;
  grid-row: 1;
  align-self: center;
  justify-self: start;
  text-align: start;
  display: flex;
  align-items: center;
  color: #404040;
`;

export const Handle = styled.p`
  grid-column: 2/2;
  grid-row: 1;
  display: flex;
  color: #808080;
  font-weight: 400;
  font-size: 0.8em;
  justify-self: start;
  text-align: start;
  align-self: end;
`;

export const Archetype = styled.span`
  grid-column: 2/2;
  grid-row: 1;
  display: flex;
  color: #404040;
  font-weight: 400;
  font-size: 1em;
  padding: 0.25em 1.25em;
  border: solid #e0e0e0;
  border-radius: 8px;
  align-self: start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(15deg);
`;

export const TweetText = styled.p`
  grid-column: 1/3;
  margin: 0;
  color: #404040;
  font-size: 100%;
  text-align: start;
  padding-right: 2px;
`;

export const TweetDetails = styled.p`
  grid-column: 1/3;
  margin-top: 0;
  font-weight: 400;
  color: #808080;
  font-size: 100%;
  text-align: start;
`;

export const Engagement = styled.div`
  grid-column: 1/3;
  color: #404040;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  max-width: 20em;
  font-size: 100%;
  font-weight: 400;
`;

export const Icon = styled.span`
  z-index: 2;
  margin-right: 0.4em;
`;
