import React from "react";
import { DetailContainer, Avatar, Name, Handle } from "./UserDetailsStyles";

const UserDetails = (session) => {
  return (
    <DetailContainer>
      <Avatar src={session.twitterUser.profile_image_url} alt="Avatar" />
      <Name>{session.twitterUser.name}</Name>
      <Handle>@{session.twitterUser.handle}</Handle>
    </DetailContainer>
  );
};

export default UserDetails;
