import styled from "styled-components";
import { TweetCardDiv, Archetype } from "../TweetCard/TweetCardStyles";

export const CharacterContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 960px) {
    justify-content: start;
  }
`;

export const FixedTweetCardDiv = styled(TweetCardDiv)`
  width: 345px;
  @media screen and (max-width: 480px) {
    width: 100vw;
  }
`;

export const CharacterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CharacterImg = styled.img`
  border-radius: 8px;
  box-sizing: border-box;
  width: 240px;
`;

export const Domain = styled(Archetype)`
  grid-column: 2/2;
  transform: rotate(10deg);
`;
