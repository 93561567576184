import React, { useState, useEffect } from "react";
import Hero from "../components/Hero/Hero";
import Mainapp from "./Mainapp";

const Home = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch("api/me/session");
        if (!response.ok) {
          throw new Error(
            JSON`This is an HTTP error: The status is ${response.status}`
          );
        }
        let sessionData = await response.json();
        setSession(sessionData);
      } catch (err) {
        setSession(null);
      }
    };
    getData();
  }, []);

  return (
    <>{session ? <Mainapp {...session} setSession={setSession} /> : <Hero />}</>
  );
};

export default Home;
