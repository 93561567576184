import styled from "styled-components";
import { TweetCardDiv } from "../TweetCard/TweetCardStyles";

export const FriendGraphContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 960px) {
    justify-content: start;
  }
`;

export const FriendGraphDiv = styled(TweetCardDiv)`
  position: relative;
  display: flex;
  backgound: #fff;
  overflow: hidden;
  padding: 0.2em;
  width: 345px;
  height: 300px;
  @media screen and (max-width: 480px) {
    width: 100vw;
  }
`;

export const FriendLoadingDiv = styled(TweetCardDiv)`
  display: flex;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  width: 345px;
  @media screen and (max-width: 960px) {
    justify-content: start;
  }
`;

export const ProfilePic1 = styled.img`
  position: absolute;
  bottom: 85px;
  left: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  z-index: 1;
`;
export const ProfilePic2 = styled.img`
  position: absolute;
  top: 110px;
  left: 110px;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  z-index: 1;
`;
export const ProfilePic3 = styled.img`
  position: absolute;
  top: 110px;
  right: 110px;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  z-index: 1;
`;

export const ProfilePic4 = styled.img`
  position: absolute;
  bottom: 85px;
  right: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  z-index: 1;
`;

export const data = {
  labels: ["Down Bad 👺", "Cringe 😬", "Based 🫡", "Chad 💪"],
  datasets: [
    {
      data: [25, 25, 25, 25],
      backgroundColor: ["#58D697", "#4D94EB", "#9438D6", "#F5180C"],
    },
  ],
};
