import styled from "styled-components";
import { Container } from "../../globalStyles";

export const DetailContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 80px;
  align-items: center;
  ${Container}
`;

export const Avatar = styled.img`
  width: 5em;
  height: 5em;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const Name = styled.h4`
  color: #404040;
`;

export const Handle = styled.h4`
  color: #808080;
  font-weight: 400;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
