import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 50px;
  @media screen and (max-width: 960px) {
    padding: 0 30px;
  }
`;
export const MainHeading = styled.h1`
  font-size: clamp(2.3rem, 6vw, 4.5rem);
  margin-bottom: 2rem;
  color: #000000;
  width: 100%;
  letter-spacing: 4px;
  text-align: center;
`;

export const Heading = styled.h2`
  font-size: clamp(1.3rem, 13vw, 3.1rem);
  margin: ${(props) => props.$margin || ""};
  margin-bottom: ${(props) => props.$mb || ""};
  margin-top: ${(props) => props.$mt || ""};
  color: ${(props) => (!!props.$inverse ? "#404040" : "#fff")};
  letter-spacing: 0.4rem;
  line-height: 1.06;
  text-align: center;
  width: ${(props) => props.$width || "100%"};
`;
export const TextWrapper = styled.span`
  color: ${(props) => props.$color || ""};
  font-size: ${(props) => props.$size || ""};
  font-weight: ${(props) => props.$weight || ""};
  letter-spacing: ${(props) => props.$spacing || ""};
  padding: ${(props) => props.$padding || ""};
  margin: ${(props) => props.$margin || ""};
  margin-bottom: ${(props) => props.$mb || ""};
  margin-top: ${(props) => props.$mt || ""};
`;
export const Section = styled.section`
  padding: ${(props) => props.$padding || "140px 0"};
  margin: ${(props) => props.$margin || ""};
  background: ${(props) => (!!props.$inverse ? "white" : "#FDFDFD")};
  position: ${(props) => props.$position || ""};
  width: ${(props) => props.$width || "auto"};
  min-width: ${(props) => props.$minWidth || "auto"};
  max-width: ${(props) => props.$maxWidth || "auto"};
  height: ${(props) => (props.$height ? props : "auto")};
  max-height: ${(props) => props.$maxHeight || "auto"};
  min-height: ${(props) => props.$minHeight || "auto"};
  @media screen and (max-width: 768px) {
    padding: ${(props) => props.$smPadding || "70px 0"};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${(props) => props.$justify || ""};
  align-items: ${(props) => props.$align || ""};
  gap: ${(props) => props.$gap || ""};
  padding: ${(props) => props.$padding || ""};
  margin: ${(props) => props.$margin || ""};
  position: ${(props) => props.$position || ""};
  width: ${(props) => props.$width || "auto"};
  min-width: ${(props) => props.$minWidth || "auto"};
  max-width: ${(props) => !!props.$maxWidth || "auto"};
  height: ${(props) => props.$height || "auto"};
  max-height: ${(props) => props.$maxHeight || "auto"};
  min-height: ${(props) => props.$minHeight || "auto"};
  flex-wrap: ${(props) => props.$wrap || ""};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$justify || ""};
  align-items: ${(props) => props.$align || ""};
  gap: ${(props) => props.$gap || ""};
  padding: ${(props) => props.$padding || ""};
  margin: ${(props) => props.$margin || ""};
  position: ${(props) => props.$position || ""};
  width: ${(props) => props.$width || "auto"};
  min-width: ${(props) => props.$minWidth || "auto"};
  max-width: ${(props) => props.$maxWidth || "auto"};
  height: ${(props) => props.$height || "auto"};
  max-height: ${(props) => props.$maxHeight || "auto"};
  min-height: ${(props) => props.$minHeight || "auto"};
`;

export const Button = styled.button`
  background: #fff;
  background-color: #fc0200;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover:before {
    height: 0%;
  }
  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: #c80200;
  }
`;

export const ShareButton = styled.img`
  background-color: #fc0200;
  padding: 10px 20px;
  border-radius: 50%;
  width: 3.55em;
  height: 3.55em;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  border: solid #e0e0e0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover:before {
    height: 0%;
  }
  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: #c80200;
  }
`;

export const CopyButton = styled.img`
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 50%;
  width: 3.55em;
  height: 3.55em;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  border: solid #e0e0e0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover:before {
    height: 0%;
  }
  &:hover {
    color: white;
    transition: background-color 0.4s ease-in;
    background-color: #e0e0e0;
  }
`;

export const AppBackground = styled.div`
  background: #fdfdfd;
`;

export const AppHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0.75em;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: #404040;
  letter-spacing: 7%;
  @media screen and (max-width: 960px) {
    justify-content: start;
  }
`;

export const AppContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1.5fr 1.5fr;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;

export default GlobalStyle;
