import React from "react";
import { Container, MainHeading, Button } from "../../globalStyles";
import {
  HeroBackground,
  HeroSection,
  HeroText,
  ButtonWrapper,
} from "./HeroStyles";
import Carousel from "../Carousel/Carousel";

const Hero = () => {
  const handleClick = (value) => () => {
    fetch(value)
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        window.location.href = responseData.url;
      });
  };

  return (
    <HeroSection>
      <HeroBackground />
      <Container>
        <MainHeading>Alphatweet</MainHeading>
        <HeroText>Find out what your timeline says about you!</HeroText>
        <ButtonWrapper>
          <Button onClick={handleClick("/auth/generate")}>
            Sign In With Twitter 👊
          </Button>
        </ButtonWrapper>
      </Container>
      <Carousel />
    </HeroSection>
  );
};

export default Hero;
