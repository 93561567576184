import React from "react";
import {
  Animation,
  LoadingDiv,
  LoadingText,
  Blob,
  Blob2,
  Blob3,
} from "./LoadingStyles.js";

const Loading = () => {
  return (
    <>
      <Animation />
      <LoadingDiv>
        <LoadingText>Loading</LoadingText>
        <Blob />
        <Blob2 />
        <Blob3 />
      </LoadingDiv>
    </>
  );
};

export default Loading;
