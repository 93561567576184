import React from "react";
import { data, sliderSettings } from "../../data/CarouselData";
import { Section } from "../../globalStyles";
import { ReviewSlider } from "./CarouselStyles";
import TweetCard from "../TweetCard/TweetCard";

const Carousel = () => {
  const disabled = true;
  return (
    <Section
      $margin="0"
      $maxWidth="100vw"
      $padding="100px 0"
      $smPadding="25px 0"
    >
      <ReviewSlider {...sliderSettings}>
        {data.map((tweet, index) => (
          <TweetCard
            key={index}
            tweet={tweet}
            twitterUser={tweet}
            disabled={disabled}
            homestyle={{ width: "fit-content" }}
          />
        ))}
      </ReviewSlider>
    </Section>
  );
};

export default Carousel;
